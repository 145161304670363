import React from 'react';
import { Link } from '@reach/router';
import cx from 'classnames';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/Seo';
import Container from '../../components/Container/Container';
import SvgComponent from '../../assets/svg/SvgComponent';
import Theme from '../../components/Theme/Theme';
import Share from '../../components/Share/Share';
// import NewsletterBanner from '../../components/NewsletterBanner/NewsletterBanner';
import PostThumbnail from '../../components/PostThumbnail/PostThumbnail';
import styles from './PostTemplate.module.scss';
import '../../styles/prism-theme.scss';

export default function PostTemplate({ data, pageContext }) {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    const {
        postUrl,
        postTitle,
        hashtag,
        hashtags,
        title,
        thumbnailBackground,
        thumbnailTitle,
        thumbnailSubtitle,
        linkedInTitle,
        linkedInSource,
        authorTwitterAccount,
    } = markdownRemark.frontmatter;
    const { previous, next } = pageContext;

    return (
        <Theme accentKey={frontmatter.techno}>
            <SEO title={frontmatter.title} />
            <Layout style={{ position: 'relative' }}>
                <div className={styles.globalBackground}></div>
                <article>
                    <header
                        className={cx(
                            styles.header,
                            styles[frontmatter.techno]
                        )}
                    >
                        <Container>
                            <h1 className={styles.title}>
                                {frontmatter.title}
                            </h1>
                        </Container>
                    </header>
                    <div className={styles.page}>
                        <div className={styles.postThumbnail}>
                            <PostThumbnail
                                background={thumbnailBackground}
                                title={thumbnailTitle}
                                subtitle={thumbnailSubtitle}
                            />
                        </div>
                        <Container
                            size="MD"
                            style={{ position: 'relative' }}
                            className={styles.container}
                        >
                            <div className={styles.contentHeader}>
                                <div className={styles.meta}>
                                    <div className={styles.metaColumn}>
                                        <SvgComponent
                                            name={`${frontmatter.technoMonogram}`}
                                            className={styles.svgTechno}
                                        />
                                    </div>
                                    <div className={styles.metaColumn}>
                                        <p className={styles.metaHeaders}>
                                            Techno
                                        </p>
                                        <p className={styles.metaContent}>
                                            {frontmatter.techno}
                                        </p>
                                    </div>
                                    <div className={styles.metaColumn}>
                                        <p className={styles.metaHeaders}>
                                            Auteur
                                        </p>
                                        <p className={styles.metaContent}>
                                            {frontmatter.authorName}
                                        </p>
                                    </div>
                                    <div className={styles.metaColumn}>
                                        <p className={styles.metaHeaders}>
                                            Mis à jour le
                                        </p>
                                        <p className={styles.metaContent}>
                                            {frontmatter.date}
                                        </p>
                                    </div>
                                </div>
                                <Share
                                    data={{
                                        postUrl,
                                        postTitle,
                                        hashtag,
                                        hashtags: hashtags.split(','),
                                        title,
                                        linkedInTitle,
                                        linkedInSource,
                                        authorTwitterAccount,
                                    }}
                                />
                            </div>
                            <div
                                className="post markdown"
                                dangerouslySetInnerHTML={{ __html: html }}
                            />
                        </Container>
                    </div>
                </article>
                {/*
                <Container>
                    <section className={styles.sectionNewsletter}>
                        <NewsletterBanner.Component
                            displayTopSvg={true}
                            title="Restons en contact"
                            titleDisplayHeadlineLevel={1}
                            description={
                                <React.Fragment>
                                    Reçois en exclusivité les nouvelles offres,
                                    promotions et articles.
                                    <br />
                                    Pas de spam, on n'aime pas ça non plus.
                                </React.Fragment>
                            }
                            theme={NewsletterBanner.Theme.BLUE}
                            buttonTheme={NewsletterBanner.ButtonTheme.YELLOW}
                            fromPage="storeBlog"
                            fromPageDetail={title}
                        />
                    </section>
                </Container>
                    */}
                <div className={styles.navPreviousNextPosts}>
                    <nav
                        className={cx(
                            styles.subnav,
                            styles[frontmatter.techno]
                        )}
                    >
                        {previous && (
                            <Link
                                to={previous.fields.slug}
                                rel="prev"
                                className={styles.previousLink}
                            >
                                <SvgComponent
                                    name="arrowLeft"
                                    className={styles.prevIcon}
                                />
                                <h3 className={styles.cellTitle}>
                                    {previous.frontmatter.title}
                                </h3>
                            </Link>
                        )}
                        {next && (
                            <Link
                                to={next.fields.slug}
                                rel="next"
                                className={styles.nextLink}
                            >
                                <h3 className={styles.cellTitle}>
                                    {next.frontmatter.title}
                                </h3>
                                <SvgComponent
                                    name="arrowLeft"
                                    className={styles.nextIcon}
                                />
                            </Link>
                        )}
                    </nav>
                </div>
            </Layout>
        </Theme>
    );
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            excerpt(pruneLength: 160)
            html
            frontmatter {
                date(formatString: "DD/MM/YYYY")
                path
                title
                authorName
                authorUri
                techno
                technoMonogram
                thumbnailBackground
                thumbnailTitle
                thumbnailSubtitle
                postUrl
                postTitle
                hashtag
                hashtags
                title
                linkedInTitle
                linkedInSource
                authorTwitterAccount
            }
        }
    }
`;
