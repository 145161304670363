import React from 'react';
import styles from './PostThumbnail.module.scss';

const PostThumbnail = ({ background, title, subtitle, ...rest }) => {
    return (
        <div className={styles.thumbnail} {...rest}>
            <div className={styles.thumbnailText}>
                <span className={styles.thumbnailTitle}>{title}</span>
                <span className={styles.thumbnailSubtitle}>{subtitle}</span>
            </div>
        </div>
    );
};

export default PostThumbnail;
