import React from 'react';
import PropTypes from 'prop-types';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    TwitterShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    EmailIcon,
    EmailShareButton,
} from 'react-share';
import styles from './Share.module.scss';

function Share({
    data: {
        postUrl,
        postTitle,
        hashtag,
        hashtags,
        title,
        linkedInTitle,
        linkedInSource,
        authorTwitterAccount,
    },
}) {
    return (
        <div className={styles.container}>
            <FacebookShareButton
                url={postUrl}
                quote={postTitle}
                hashtag={hashtag}
                className={styles.socialIcon}
            >
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>

            <TwitterShareButton
                url={postUrl}
                title={postTitle}
                via={authorTwitterAccount}
                hashtags={hashtags}
                className={styles.socialIcon}
            >
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton
                url={postUrl}
                title={linkedInTitle}
                source={linkedInSource}
                className={styles.socialIcon}
            >
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            <EmailShareButton url={postUrl} subject={title} body={title}>
                <EmailIcon size={32} round={true} />
            </EmailShareButton>
        </div>
    );
}

Share.propTypes = {
    data: PropTypes.shape({
        postUrl: PropTypes.string,
        postTitle: PropTypes.string,
        hashtag: PropTypes.string,
        hashtags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        linkedInTitle: PropTypes.string,
        linkedInSource: PropTypes.string,
        authorTwitterAccount: PropTypes.string,
    }),
};

// TODO: Vérifier ces paramètres
Share.defaultProps = {
    data: {
        postUrl: 'https://ledevwebmoderne.fr',
        postTitle: '',
        hashtag: '#ledevwebmoderne',
        hashtags: [],
        title: '',
        linkedInTitle: '',
        linkedInSource: 'https://ledevwebmoderne.fr',
        authorTwitterAccount: 'OlivierSubirana',
    },
};

export default Share;
